@tailwind utilities;
@import 'variables';

body {
  margin: 0;
}

.btnOutlinePrimary {
  border-radius: 4px;
  border: 1px solid $primary;
  padding: 0.54rem 1rem;
  font-size: $font-size-sm;
  color: $primary;
  text-decoration: none;
  text-transform: uppercase;
  font-family: $font-family;
  display: flex;

  svg {
    fill: $primary;
    height: 20px;
  }
}

.MuiTypography-root#full-width {
  .MuiBreadcrumbs-ol {
    :last-child.MuiBreadcrumbs-li {
      width: auto !important;
    }
  }
}

.MuiMenuList {
  padding: 0;
}

.MuiTableCell-head {
  font-weight: 600;
}

.editTableData {
  border-bottom: 1px solid #e0e0e0;

  .editInput,
  .editInputNoPointer {
    padding-left: 4px;
    padding-right: 4px;
    position: relative;

    input {
      text-overflow: ellipsis;
      padding-top: 6px;
      padding-bottom: 7px;
    }
  }

  &.friday {
    border-bottom-width: 4px;
  }

  &:not(.active) {
    .editInput {
      &:before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background-color: transparent;
        z-index: 1;
        cursor: pointer;
      }

      .MuiSelect-icon {
        display: none;
      }

      input {
        border-color: transparent;
      }

      fieldset {
        display: none;
      }
    }

    .editInputNoPointer {
      &:before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background-color: transparent;
        z-index: 1;
      }

      .MuiSelect-icon {
        display: none;
      }

      input {
        border-color: transparent;
      }

      fieldset {
        display: none;
      }
    }

    .btnArea {
      display: none;
    }

    .btnAreaAction {
      display: flex;
    }
  }

  &.active {
    &:hover {
      background-color: transparent;
    }

    .btnArea {
      display: flex;
    }

    .btnAreaAction {
      display: none;
    }

    // td {
    //   height: 60px;
    //   vertical-align: top !important;
    //   padding-top: 28px;

    //   &:not(.editInput, .editInputNoPointer, .btnActionArea) {
    //     display: none;
    //   }
    // }
  }
}

// source: https: //aguidehub.substack.com/p/how-to-hide-arrows-from-input-number-in-react
// we want to hide arrows/ up down from input
.hide-input-number-arrows {
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    //-moz-appearance: textfield;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
}

.text-field-price-limit {
  input {
    text-align: right;
  }
}

.btn-export {
  background-color: #2196f3;
  color: #ffffff;
  border: 1px solid #2196f3;

  &:hover {
    background-color: #1992f5;
    color: #ffffff;
    border: 1px solid #1992f5;
  }
}

.text-select-restaurant {
  color: $primary;
}
